import { Box } from '@mui/material';
import AverageScore from './AverageScore';
import ClassOverallCard from '../../component/OverallResultPage/ClassOverallCard';
import DifficultQuestion from '../../component/OverallResultPage/DifficultQuestion';
import ResultPageCard from '../../component/OverallResultPage/ResultPageCard';
import { useTestroomResult } from 'pages/results/hook/useTestroomResult';
import TopPerformanceCard from 'pages/results/component/OverallResultPage/TopPerformanceCard';
import OverallQuestionTypePerformance from 'pages/results/component/OverallClassResultPage/OverallQuestionTypePerformance';
import { PopupCircularProgress } from 'components/General/Popups/PopupCircularProgress';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const OverallResultPage = () => {
    const {
        questionTypeResult,
        studentTableData,
        allStudentsAverageAnswerTime,
        numberOfQuestion,
        absentStudents,
        badPerformanceStudents,
        failedCompeteStudents,
        hardQuestions,
        loadingOverallResultPage,
    } = useTestroomResult();
    const { mode } = useParams();
    const { t } = useTranslation();

    return (
        <Box
            sx={{
                display: 'flex',
                padding: '0px',
                justifyContent: 'center',
                alignItems: 'flex-start',
                gap: '16px',
                flex: '1 0 0',
                flexDirection: 'column',
                width: '100%',
            }}>
            <PopupCircularProgress isOpened={loadingOverallResultPage} />
            <TopPerformanceCard studentData={studentTableData} />
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    gap: '16px',
                    flex: '1 0 0',
                    flexDirection: 'row',
                }}>
                <AverageScore studentData={studentTableData} questionNumber={numberOfQuestion} />
                <ClassOverallCard studentData={studentTableData} numberOfQuestion={numberOfQuestion} averageTime={allStudentsAverageAnswerTime} />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    gap: '16px',
                    flex: '1 0 0',
                    flexDirection: 'row',
                }}>
                <OverallQuestionTypePerformance questionTypeResult={questionTypeResult} />
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                    gap: '16px',
                    flex: '1 0 0',
                    flexDirection: 'row',
                }}>
                <DifficultQuestion difficultQuestion={hardQuestions} absentStudents={absentStudents?.length} />
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'row',
                        gap: '8px',
                        width: '100%',
                    }}>
                    <ResultPageCard header={t('resultPage.unfinished_Test')} students={failedCompeteStudents} numberOfStudent={failedCompeteStudents?.length} />
                    <ResultPageCard header={t('resultPage.need-Attention')} students={badPerformanceStudents} numberOfStudent={badPerformanceStudents?.length} />
                    <ResultPageCard header={mode === 'Homework' ? t('resultPage.missing') : t('resultPage.absent')} students={absentStudents} numberOfStudent={absentStudents?.length} />
                </Box>
            </Box>
        </Box>
    );
};

export default OverallResultPage;
